import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  Button,
  Drawer,
  FormGroup,
  FormControlLabel,
  Checkbox,
  // IconButton,
  // Dialog,
  // TextField,
  Tooltip,
  Typography,
  // Image,
} from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
import html2Canvas from "html2canvas";

import InviteMemberDlg from "./InviteMemberDlg";
import ParticipantComponent from "./ParticipantComponent";
import {
  saveCurrentMapID,
  clearCurrentMapData,
  setActivemap,
  // uploadMapData,
  addPartipant,
  // startedNewMap,
  // changeColorSystem,
  uploadMapImage,
  add_token,
  removePartipantById,
  deleteMap,
  setLoopView,
  UpdateFullSpotlight,
  User_CurrentlyInMap,
  setLoopLabels,
  updateCycleStatus,
} from "../../redux/actions/nodeAction";
import { saveSelectedTabIndex } from "../../redux/actions/folderAction";
// import { logout } from "../../redux/actions/authAction";
import { deleteMouseMovement } from "../../redux/actions/movementAction";
import { clearChatHistory } from "../../redux/actions/chatAction";
import {
  USE_FIRST_NAME,
  removeFocus,
  // COLOR_BIND_FRIENDLY,
} from "../../utils/global";

import "../../styles/components/SideBar/index.scss";
// import ConfirmDialog from "../Canvas/ConfirmDialog";
import { Share } from "../../assets/menuIcons/index";
import { getGPUTier } from "detect-gpu";

const SidebarComponent = ({
  open,
  onClose,
  goBackToHome,
  userType,
  setBackDrop,
  handleChangeVariableText,
  showVariableText,
  handleShowProgressbar,
  isShowTopProgressBar,
  // setFocuedCycle,
  handleChangeLowResMode,
  lowResMode,
}) => {
  //handleChangeShowAnimations, showAnimations,
  const {
    selectedMapData,
    isValidEmail,
    // maps,
    cycles,
    loopView,
  } = //isLoading, colorSystem
    useSelector((state) => state.node);
  const [members, setMembers] = useState(null);
  const [useFirstname, setUseFirstname] = useState(false);
  const [useHidefeedback, setUseHidefeedback] = useState(
    selectedMapData?.FullSpotlight ? selectedMapData?.FullSpotlight : false
  );
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  // const [openImageDlg, setOpenImageDlg] = useState(false);
  const [inviteMembers, setInviteMembers] = useState([""]);
  const [errText, setErrText] = useState("");
  // const [isOpenConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [setdrop, setdropvalue] = useState("Editor");
  const dispatch = useDispatch();
  const history = useHistory();
  const [dropValue, setDrovalue] = useState(null);
  const usersMovements = useSelector((state) => state.movement);
  const auth = useSelector((state) => state.auth);
  const [isLoopLabels, setIsLoopLabels] = useState(true);
  // const [type, setType] = useState("Template");
  useEffect(() => {
    if (selectedMapData === null || auth.email === null) {
      return; // Exit early if there's no map data or email
    }
  
    if (!selectedMapData.creator) {
      return; // Exit if there's no creator data
    }
  
    selectedMapData.creator.forEach((value) => {
			// Check if the current user's email matches
			if (auth.email === value.email) {
				setDrovalue(value.dropvalue);
			}

			// Check if random_id is not empty and timestamp is older than one week
			if (
				value.random_id &&
				Math.floor((Date.now() - value.timestamp) / 1000) > 604800
			) {
				dispatch(removePartipantById(value.random_id));
			}
		});
  }, [selectedMapData, auth, dispatch]);

  useEffect(() => {
    if (
      selectedMapData &&
      selectedMapData.data &&
      history.location.pathname !== "/main"
    ) {
      // start for FullSpotlight
      var data = [],
        Cyclenode = [],
        unUsepoint = [];
      selectedMapData &&
        selectedMapData.data.map((item) => {
          return data.push(item.nodeId);
        });
      // for (let i = 0; i < selectedMapData && selectedMapData.data.length; i++) {
      //   console.log("first", selectedMapData.data.nodeId[i]);
      //   return data.push(selectedMapData.data.nodeId[i]);
      // }
      if (cycles) {
        cycles &&
          cycles.map((item) => item.map((value) => Cyclenode.push(value)));
      }
      data &&
        data.map(
          (item, index) => !Cyclenode.includes(item) && unUsepoint.push(item)
        );
      if (
        (selectedMapData && selectedMapData?.FullSpotlight === false) ||
        (selectedMapData && selectedMapData?.FullSpotlight === undefined)
      ) {
        dispatch(setLoopView(loopView));
      } else {
        dispatch(setLoopView(loopView, true, unUsepoint));
      }
      //End for FullSpotlight
    }
  }, [selectedMapData, dispatch]);
  useEffect(() => {
    window.addEventListener("beforeunload", removePartipant_ById);
    return () => {
      window.removeEventListener("beforeunload", removePartipant_ById);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("afterload", removePartipant_ById);
    return () => {
      window.addEventListener("afterload", removePartipant_ById);
    };
  }, []);
  useEffect(() => {
    dispatch(setLoopLabels(isLoopLabels));
  }, [isLoopLabels]);

  const removePartipant_ById = () => {
    if (sessionStorage.getItem("random_id") !== null) {
      dispatch(removePartipantById(sessionStorage.getItem("random_id")));
    }
  };
  // useEffect(() => {
  //   const savedUseFirstname = localStorage.getItem(USE_FIRST_NAME);
  //   if (savedUseFirstname && savedUseFirstname === "true") {
  //     setUseFirstname(true);
  //   }
  // }, []);

  useEffect(() => {
    if (open) {
      removeFocus();
    }
  }, [open]);

  useEffect(() => {
    if (isValidEmail.length > 0) {
      setErrText("This email is not registered to Cauzality");
    } else if (isValidEmail.length === 0) {
      const clearInviteDlg = setTimeout(() => {
        handleCloseInviteDlg();
      }, 3000);
      return () => {
        clearTimeout(clearInviteDlg);
        setErrText("");
      };
    }
  }, [isValidEmail]);

  useEffect(() => {
    if (selectedMapData) {
      const members =
        selectedMapData.creator &&
        selectedMapData.creator?.map((creator, ind) => ({
          id: ind + 1,
          email: creator.email,
          displayName: creator.displayName,
          dropvalue: creator.dropvalue,
          color: creator.color,
          random_id: creator.random_id,
          currentlyInMap: creator?.currentlyInMap,
          timestamp: creator.timestamp,
        }));
      setMembers(members);
    }
  }, [selectedMapData]);

  const handleChangeFirstName = () => {
    if (selectedMapData && usersMovements && usersMovements.users) {
      var checkName = sessionStorage.getItem("guestLogin")
        ? sessionStorage.getItem("guestLogin")
        : auth.displayName;
      const selectedUsersActions = usersMovements.users.filter((movement) => {
        return (
          selectedMapData &&
          movement.mapId === selectedMapData.id &&
          checkName !== movement.userName
        );
      });
      if (selectedUsersActions?.length) {
        selectedUsersActions.forEach((action) => {
          dispatch(deleteMouseMovement(action.id));
        });
      }
    }
    if (useFirstname) {
      setUseFirstname(false);
      localStorage.setItem(USE_FIRST_NAME, "false");
    } else {
      setUseFirstname(true);
      localStorage.setItem(USE_FIRST_NAME, "true");
    }
  };

  const addParticipants = () => {
    dispatch(
      add_token(
        selectedMapData.id,
        selectedMapData.name,
        localStorage.getItem("user_id"),
        setdrop
      )
    );
    setOpenInviteDialog(true);
  };
  // const CustomToastWithLink_basic = () => (
  //   <div style={{ textAlign: "center" }}>
  //     You've reached your map limit.{" "}
  //     <a
  //       href="https://cauzality.com/plans/"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       Upgrade
  //     </a>{" "}
  //     your account for unlimited maps.
  //   </div>
  // );
  // const CustomToastWithLink_starter = () => (
  //   <div style={{ textAlign: "center" }}>
  //     You've reached your map limit.{" "}
  //     <a
  //       href="https://cauzality.com/plans/"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       Upgrade
  //     </a>{" "}
  //     for more maps.
  //   </div>
  // );

  // const createNewMap = () => {
  //   if (userType === "starter" && maps && maps.length >= 3) {
  //     toast(CustomToastWithLink_starter);
  //     return;
  //   }
  //   if (userType === "basic" && maps && maps.length >= 12) {
  //     toast(CustomToastWithLink_basic);
  //     return;
  //   }

  //   dispatch(deleteMouseMovement());
  //   dispatch(saveCurrentMapID(null));
  //   dispatch(clearCurrentMapData());
  //   dispatch(clearChatHistory());
  //   dispatch(setActivemap(true));
  //   dispatch(uploadMapData(null));
  //   dispatch(startedNewMap(true));
  //   onClose();
  //   setTimeout(() => {
  //     dispatch(startedNewMap(false));
  //   }, 1000);
  // };

  const backToHome = async () => {
    if (usersMovements && usersMovements.users) {
      if (usersMovements.users?.length) {
        await usersMovements.users.forEach(async (action) => {
          await dispatch(deleteMouseMovement(action.id));
        });
      }
    }
    setBackDrop();
    await setTimeout(async () => {
      const canvas = await html2Canvas(
        document
          .querySelector("iframe")
          ?.contentDocument.body.querySelector("canvas")
      );
      const canvasImage = canvas.toDataURL();
      await dispatch(uploadMapImage(canvasImage));
      dispatch(saveCurrentMapID(null));
      dispatch(clearCurrentMapData());
      dispatch(clearChatHistory());
      dispatch(setActivemap(false));
      dispatch(saveSelectedTabIndex(0));
      goBackToHome();
      onClose();
    }, 500);
  };

  const handleCloseInviteDlg = () => {
    setInviteMembers([""]);
    setOpenInviteDialog(false);
  };

  const handleChange = (event, ind) => {
    let newInviteMembers = inviteMembers.slice();
    newInviteMembers[ind] = event.target.value;
    setInviteMembers(newInviteMembers);
  };

  const decreaseInviteMember = (ind) => {
    if (inviteMembers.length !== 1) {
      let remove_Email = inviteMembers;
      remove_Email = remove_Email.filter((_, i) => ind !== i);
      setInviteMembers(remove_Email);
    }
  };
  const increaseInviteMember = () => {
    let newInviteMembers = inviteMembers.slice();
    newInviteMembers.push("");
    setErrText("");
    setInviteMembers(newInviteMembers);
  };

  const sendInvite = () => {
    dispatch(addPartipant(inviteMembers, setdrop));
  };

  // const onLogout = () => {
  //   if (sessionStorage.getItem("random_id")) {
  //     dispatch(removePartipantById(sessionStorage.getItem("random_id")));
  //     dispatch(deleteMouseMovement());
  //     dispatch(logout());
  //     dispatch(saveCurrentMapID(null));
  //     dispatch(clearChatHistory());
  //     dispatch(clearCurrentMapData());
  //     dispatch(setActivemap(false));
  //     localStorage.clear();
  //     sessionStorage.clear();

  //     history.push("/login");
  //   } else {
  //     dispatch(deleteMouseMovement());
  //     dispatch(logout());
  //     dispatch(saveCurrentMapID(null));
  //     dispatch(clearChatHistory());
  //     dispatch(clearCurrentMapData());
  //     dispatch(setActivemap(false));
  //     localStorage.clear();
  //     sessionStorage.clear();

  //     history.push("/login");
  //   }
  // };

  // const handleCloseImageDlg = () => {
  //   setOpenImageDlg(false);
  // };

  // const handleOpenImageDlg = () => {
  //   setOpenImageDlg(true);
  // };

  // const downloadMap = (e) => {
  //   if (userType !== "premium") {
  //     handleOpenImageDlg();
  //     return;
  //   }

  //   html2Canvas(
  //     document
  //       .querySelector("iframe")
  //       .contentDocument.body.querySelector("canvas")
  //   ).then((canvas) => {
  //     const canvasImage = canvas.toDataURL(`${selectedMapData.name}.png`, 1.0);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", canvasImage);
  //     link.setAttribute("download", `${selectedMapData.name}.png`);
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // };

  // const printMap = (e) => {
  //   if (userType !== "premium") {
  //     handleOpenImageDlg();
  //     return;
  //   }

  //   setOpenConfirmDlg(true);
  // };

  // const handleCloseConfirmModal = () => {
  //   setOpenConfirmDlg(false);
  // };

  // const handlePrintMap = () => {
  //   const content = document.querySelector("iframe").contentWindow;
  //   content.focus();
  //   content.print();
  // };

  const onDropValue = (value) => {
    setdropvalue(value);
  };
  const handleChangeHidefeedback = () => {
    setUseHidefeedback(!useHidefeedback);
    var data = [],
      Cyclenode = [],
      unUsepoint = [];
    selectedMapData.data.map((item, index) => data.push(item.nodeId));
    cycles && cycles.map((item) => item.map((value) => Cyclenode.push(value)));
    data.map(
      (item, index) => !Cyclenode.includes(item) && unUsepoint.push(item)
    );
    // setFocuedCycle(null);
    if (useHidefeedback) {
      dispatch(setLoopView(loopView));
      dispatch(UpdateFullSpotlight(false));
    } else {
      dispatch(setLoopView(loopView, true, unUsepoint));
      dispatch(UpdateFullSpotlight(true));
    }
  };
  const BackToHome = async () => {
    if (
      selectedMapData &&
      selectedMapData.creator?.length === 1 &&
      ((selectedMapData && selectedMapData.data?.length === 0) ||
        (selectedMapData && selectedMapData.data?.length === undefined)) &&
      selectedMapData.name === "My Map"
    ) {
      dispatch(deleteMap(selectedMapData));
    }
    const gpuTier = await getGPUTier();
    dispatch(saveSelectedTabIndex(0));
    await setTimeout(async () => {
      setBackDrop();
      console.log("gpuTier.type ----", gpuTier.type);
      // if (gpuTier.type !== "FALLBACK") {
      const initalSelectLooplabel = selectedMapData?.cycles?.map((cur) => {
        return { ...cur, isOpen: false, isEditable: false };
      });
      const canvas = await html2Canvas(
        document
          .querySelector("iframe")
          ?.contentDocument.body.querySelector("canvas")
      );
      const canvasImage = canvas.toDataURL();
      await dispatch(uploadMapImage(canvasImage));
      // }
      dispatch(updateCycleStatus(initalSelectLooplabel));
      dispatch(User_CurrentlyInMap(auth?.email, false));
      dispatch(saveCurrentMapID(null));
      dispatch(clearCurrentMapData());
      dispatch(setActivemap(false));
    }, 500);
  };

  const handleLoopLables = () => {
    setIsLoopLabels(!isLoopLabels);
    dispatch(setLoopLabels(isLoopLabels));
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div
        id="global-backdrop"
        className="global-backdrop"
        style={{ display: "none" }}
      ></div>
      <div className="sidebar-container">
        {auth.email != null ? (
          <section className="sidebar-title">
            <Typography
              className="title title-sidebar"
              onClick={() => BackToHome()}
            >
              <img src="/assets/home-icon.svg" alt="home-icon" /> Back to Map
              Library
            </Typography>
          </section>
        ) : (
          ""
        )}
        <div className="main-container">
          <section className="participants-container">
            {dropValue === "Owner" &&
            selectedMapData &&
            selectedMapData.type === "Template" ? (
              <>
                <section>
                  {/* <h2 className="title title-subject">
                    This template has{" "}
                    {selectedMapData?.cloning_users?.length | 0}{" "}
                    {selectedMapData?.cloning_users?.length > 1
                      ? "users"
                      : "user"}
                  </h2> */}
                  <Button
                    disableFocusRipple
                    className="btn-addMember"
                    onClick={addParticipants}
                  >
                    <img src={Share} style={{ paddingRight: 5 }} alt="Share" />
                    Share template
                  </Button>
                </section>
              </>
            ) : (
              <>
                <h2 className="title title-subject">Collaborators</h2>
                {members &&
                  members.map((member, index) => (
                    <ParticipantComponent
                      key={member.id}
                      onClose={onClose}
                      email={member.email}
                      random_id={member.random_id}
                      displayName={member.displayName}
                      dropvalue={member.dropvalue}
                      color={member.color}
                      timestamp={member.timestamp}
                      currentlyInMap={member?.currentlyInMap}
                      backToHome={backToHome}
                      memberIndex={index}
                      useFirstname={useFirstname}
                    />
                  ))}
                {auth.email !== null && (
                  <Button
                    disableFocusRipple
                    className="btn-addMember"
                    onClick={addParticipants}
                  >
                    Invite
                  </Button>
                )}
              </>
            )}
          </section>
        </div>
        <section className="footer-menu">
          {/* {auth.email != null ? ( */}
          <section className="settings-container">
            <h2 className="title title-subject">Settings</h2>
            <FormGroup column="true" className="checkbox-container">
              {selectedMapData && selectedMapData.type !== "Template" && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useFirstname}
                        onChange={handleChangeFirstName}
                        name="useFirstname"
                      />
                    }
                    label="Use full names"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showVariableText}
                        onChange={handleChangeVariableText}
                        name="showVariableText"
                      />
                    }
                    label={
                      <>
                        Show variable text on hover{" "}
                        <Tooltip
                          title="Useful for reading long variable names and small text"
                          leaveDelay={200}
                        >
                          <img
                            src="/assets/question-mark.svg"
                            alt="question-mark"
                          />
                        </Tooltip>
                      </>
                    }
                    className="control"
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isShowTopProgressBar}
                    onClick={handleShowProgressbar}
                    name="Show level progress"
                  />
                }
                label={
                  <>
                    Show level progress
                    <Tooltip
                      title="The level progress is a dynamic tool that refers to the advancement and depth of your map."
                      leaveDelay={200}
                    >
                      <img
                        src="/assets/question-mark.svg"
                        alt="question-mark"
                      />
                    </Tooltip>
                  </>
                }
                className="control"
              />
              <label
                style={{
                  margin: "10px 0px 10px 0px",
                  color: "#cbd1cc",
                  fontWeight: 600,
                }}
                className="title"
              >
                FEEDBACK LOOPS
              </label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLoopLabels}
                    onClick={() => {
                      handleLoopLables();
                    }}
                    name="Show feedback loop labels"
                  />
                }
                label="Show feedback loop labels"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedMapData && selectedMapData?.FullSpotlight}
                    onClick={() => {
                      handleChangeHidefeedback();
                    }}
                    name="Full spotlight for feedback loops"
                  />
                }
                label="Full spotlight for feedback loops"
              />
              {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={useFirstname}
                      onChange={handleChangeFirstName}
                      name="useFirstname"
                    />
                  }
                  label="Use full names"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useHidefeedback}
                      onClick={()=>{handleChangeHidefeedback() }}
                      name="Full spotlight for feedback loops"
                    />
                  }
                  label="Full spotlight for feedback loops"
                /> */}
              {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowResMode}
                      name="lowResMode"
                    />
                  }
                  onChange={handleChangeLowResMode}
                  label="Low-res mode"
                  className="control"
                /> */}
              {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAnimations}
                      name="showAnimations"
                    />
                  }
                  onChange={handleChangeShowAnimations}
                  label="Show animations"
                  className="control"
                /> */}
            </FormGroup>
          </section>
          {/* ) : (
            ""
          )} */}
          {/*  {auth.email != null ? (
            <>
              <Button className="btn-menu" onClick={createNewMap}>
                <img src="/assets/ico-plus.svg" alt="" />
                New map
              </Button>
              <Button className="btn-menu" onClick={downloadMap}>
                <img src="/assets/ico-download.svg" alt="" />
                Download Map
                <Tooltip title="Start using our newest features before they're widely available">
                  <span className="btn-menu-badge">Labs</span>
                </Tooltip>
              </Button>
              <Button className="btn-menu" onClick={printMap}>
                <img src="/assets/ico-print.svg" alt="" />
                Print map
                <Tooltip title="Start using our newest features before they're widely available">
                  <span className="btn-menu-badge">Labs</span>
                </Tooltip>
              </Button>
              <Button className="btn-menu" onClick={backToHome}>
                <img src="/assets/ico-home.svg" alt="" />
                Back to home
              </Button>
            </>
          ) : (
            ""
          )}
          <Button className="btn-menu" onClick={onLogout}>
            <img src="/assets/ico-signout.svg" alt="" />
            Sign out
          </Button>*/}
        </section>
      </div>
      <InviteMemberDlg
        onAddMember={increaseInviteMember}
        onClosed={handleCloseInviteDlg}
        onConfirm={sendInvite}
        onDropvalue={onDropValue}
        inviteMembers={inviteMembers}
        open={openInviteDialog}
        errText={errText}
        isValidEmail={isValidEmail}
        onHandleChange={handleChange}
        Maptype={selectedMapData && selectedMapData.type}
        onRemoveMember={decreaseInviteMember}
      />
      {/* <Dialog
        onClose={handleCloseImageDlg}
        aria-labelledby="customized-dialog-title"
        open={openImageDlg}
      >
        <div className="invite-dlg">
          <IconButton className="btn-close" onClick={handleCloseImageDlg}>
            <CloseIcon />
          </IconButton>
          <p style={{ width: 400 }}>
            Download or print a high res image of your map any time from your
            map menu (available now to Premium subscribers with Cauzality Labs)
          </p>
        </div>
      </Dialog> */}
      {/* <ConfirmDialog
        open={isOpenConfirmDlg}
        title="Print Map"
        description="Printed images are based on your current view of the map."
        okButtonLabel="Ok"
        cancelButtonLabel="Cancel"
        onClosed={handleCloseConfirmModal}
        onConfirmed={handlePrintMap}
      /> */}
    </Drawer>
  );
};

export default SidebarComponent;
