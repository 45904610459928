import {
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESSED,
  AUTH_LOGOUT,
  AUTH_SIGN_UP_FAILED,
  AUTH_SIGN_UP_REQUEST,
  AUTH_SIGN_UP_REQUEST_SUCCESS,
  AUTH_ADD_MEMBER_REQUEST,
  AUTH_ADD_MEMBER_SUCCESS,
  AUTH_ADD_MEMBER_FAILED,
  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_SUCCESS,
  COMPLETE_SIGNUP_FAILED,
  SET_ADMIN_MODE,
  SAVE_USER_SUBSCRIPTION,
  UPLOAD_AVATAR_COLOR_SUCCESSED,
  UPLOAD_AVATAR_COLOR_FAILED,
} from "../actionTypes";

const initialState = {
  isSuccess: false,
  isLoading: false,
  signupRequestResult: false,
  addMemberResult: null,
  confirmSignUpResult: null,
  newPasswordResult: null,
  updateUserInfoResult: null,
  errCode: null,
  email: null,
  photoURL: null,
  isAdmin: false,
  permission: null,
  avatarColor: {},
  isApiCallFinish: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_LOGIN_REQUEST: {
      return {
        ...state,
        ...payload,
        isSuccess: false,
        errCode: null,
        isLoading: true,
        isApiCallFinish: false,
      };
    }
    case AUTH_LOGIN_SUCCESSED: {
      return {
        ...state,
        ...payload,
        isSuccess: true,
        errCode: null,
        isLoading: false,
        isApiCallFinish: true,
      };
    }
    case AUTH_LOGIN_FAILED: {
      return {
        ...state,
        isSuccess: false,
        errCode: payload,
        isLoading: false,
        isApiCallFinish: true,
      };
    }
    case AUTH_LOGOUT: {
      return initialState;
    }
    case AUTH_SIGN_UP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        signupRequestResult: false,
      };
    }
    case AUTH_SIGN_UP_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        signupRequestResult: true,
      };
    }
    case AUTH_SIGN_UP_FAILED: {
      return {
        ...state,
        isLoading: false,
        signupRequestResult: false,
      };
    }
    case AUTH_ADD_MEMBER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        addMemberResult: false,
      };
    }
    case AUTH_ADD_MEMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addMemberResult: true,
      };
    }
    case AUTH_ADD_MEMBER_FAILED: {
      return {
        ...state,
        isLoading: false,
        addMemberResult: false,
      };
    }
    case COMPLETE_SIGNUP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        confirmSignUpResult: false,
      };
    }
    case COMPLETE_SIGNUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        confirmSignUpResult: true,
      };
    }
    case COMPLETE_SIGNUP_FAILED: {
      return {
        ...state,
        isLoading: false,
        confirmSignUpResult: false,
      };
    }
    case SET_ADMIN_MODE: {
      return {
        ...state,
        isAdmin: payload,
      };
    }
    case SAVE_USER_SUBSCRIPTION: {
      return {
        ...state,
        permission: payload,
      };
    }
    case UPLOAD_AVATAR_COLOR_SUCCESSED: {
      return {
        ...state,
        isLoading: false,
        avatarColor: payload,
      };
    }
    case UPLOAD_AVATAR_COLOR_FAILED: {
      return {
        ...state,
        isLoading: false,
        addAvatarColorResult: false,
      };
    }
    default: {
      return state;
    }
  }
}
