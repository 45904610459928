import {
  MAP_LOAD_DATA_REQUEST,
  MAP_LOAD_DATA_SUCCESSED,
  MAP_LOAD_DATA_FAILED,
  SAVE_CURRENT_MAP_ID,
  SAVE_CURRENT_MAP_DATA,
  UPLOAD_CURRENT_MAP_REQUEST,
  UPLOAD_CURRENT_MAP_SUCCESS,
  UPLOAD_CURRENT_MAP_FAILED,
  CHECK_VALID_EMAIL_REQUEST,
  CHECK_VALID_EMAIL_SUCCESS,
  CHECK_VALID_EMAIL_FAILED,
  SET_ACTIVE_MAP,
  SAVE_CYCLES,
  SAVE_SELECTED_LOOP_AREA,
  SET_LOOP_VIEW,
  SET_LOOP_LABEL,
  SHOW_LEVERAGE_LOOP,
  CLEAR_CURRENT_MAP_DATA,
  CREATED_NEW_MAP,
  CHANGE_COLOR_SYSTEM,
  CLEAR_MAPS,
  SAVE_TOKEN,
  CLONE_SUCCESS,
} from "../actionTypes";
// import { COLOR_BIND_FRIENDLY } from "../../utils/global";

const initialState = {
  loopView: null,
  isValidEmail: [],
  colorSystem: true,
};

export default function (
  state = initialState,
  { type, payload, isHideFully = false, unUsepoint = null }
) {
  switch (type) {
    case MAP_LOAD_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CHECK_VALID_EMAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        // isValidEmail: [],
      };
    }
    case CHECK_VALID_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isValidEmail: [],
      };
    }
    case CHECK_VALID_EMAIL_FAILED: {
      return {
        ...state,
        isLoading: false,
        isValidEmail: payload,
      };
    }
    case MAP_LOAD_DATA_SUCCESSED: {
      return {
        ...state,
        isLoading: false,
        maps: payload,
      };
    }
    case MAP_LOAD_DATA_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SAVE_CURRENT_MAP_ID: {
      return {
        ...state,
        selectedMapId: payload,
      };
    }
    case SAVE_CURRENT_MAP_DATA: {
      return {
        ...state,
        selectedMapData: {
          ...state.selectedMapData,
          ...payload,
        },
      };
    }
    case UPLOAD_CURRENT_MAP_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPLOAD_CURRENT_MAP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPLOAD_CURRENT_MAP_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_ACTIVE_MAP: {
      return {
        ...state,
        isActiveMap: payload,
      };
    }
    case SAVE_CYCLES: {
      return {
        ...state,
        cycles: payload,
      };
    }
    case SAVE_SELECTED_LOOP_AREA: {
      return {
        ...state,
        loopArea: payload,
      };
    }
    case CLEAR_CURRENT_MAP_DATA: {
      return {
        ...state,
        selectedMapData: null,
      };
    }
    case SET_LOOP_VIEW: {
      return {
        ...state,
        loopView: payload,
        isHideFully: isHideFully,
        unUsepoint: unUsepoint,
      };
    }
    case SET_LOOP_LABEL: {
      return {
        ...state,
        isLoopLabels: payload,
      };
    }
    case SHOW_LEVERAGE_LOOP: {
      return {
        ...state,
        hoverStatus: payload,
      };
    }
    case CREATED_NEW_MAP: {
      return {
        ...state,
        createdNewMap: payload,
      };
    }
    case CHANGE_COLOR_SYSTEM: {
      return {
        ...state,
        colorSystem: payload,
      };
    }
    case CLEAR_MAPS: {
      return initialState;
    }
    case SAVE_TOKEN: {
      return {
        ...state,
        selectedMapData: {
          ...state.selectedMapData,
          token: payload,
        },
      };
    }
    case CLONE_SUCCESS: {
      return {
        ...state,
        clone_data: payload,
      };
    }
    default: {
      return state;
    }
  }
}
