import React, { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import NotSupport from "./container/NotSupport";
import AuthenticatedRoute from "./Layouts/authenticatedRoute";
import GetMapByToken from "./container/getMapByToken";
// import TemplateCloneByUrl from "./container/templateCloneByUrl";
import { authRef } from "./core/firebase";
import { DEFAULT_EMAIL, loginSuccess } from "./redux/actions/authAction";
// import { Dialpad } from "@material-ui/icons";
const Home = lazy(() => import("./container/Home"));
const Main = lazy(() => import("./container/Main"));
const Login = lazy(() => import("./container/Login"));
const TemplateCloneByUrl = lazy(() => import("./container/templateCloneByUrl"));
const AdminDashboard = lazy(() => import("./container/AdminDashboard"));
const FinishSignUp = lazy(() => import("./container/FinishSignUp"));
const ChangePassword = lazy(() => import("./container/ChangePassword"));

const App = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    document.body.addEventListener("touchmove", (e) => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    authRef.onAuthStateChanged((user) => {
      if (user) {
        const { email, displayName, photoURL, uid } = user;
        if (email !== DEFAULT_EMAIL) {
          const currentUser = { email, displayName, photoURL, uid };
          dispatch(loginSuccess(currentUser));
        }
      }
    });
  }, [auth.isAdmin, dispatch]);

  // useEffect(() => {
  //   authRef.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       const lastSignInTime = new Date(user.metadata.lastSignInTime);
  //       const lastSignInTimeTimeStamp = Math.round(
  //         lastSignInTime.getTime() / 1000
  //       );
  //       const yesterdayTimeStamp =
  //         Math.round(new Date().getTime() / 1000) - 24 * 3600;
  //       if (lastSignInTimeTimeStamp < yesterdayTimeStamp) {
  //         await firebase.auth().signOut();
  //         localStorage.clear();
  //         dispatch(logout());
  //         history.push("/login");
  //       }
  //     }
  //   });
  // }, [dispatch, history, auth]);

  // useEffect(() => {
  //   if (auth.email && auth.isAdmin) {
  //     history.push("/admin");
  //   } else if (auth.email && !auth.isAdmin) {
  //     history.push("/home");
  //   }
  // }, [auth, history]);

  useEffect(() => {
    const { location } = history;
    let isTokenUrl = false;
    if (
      location &&
      location.pathname &&
      (location.pathname.includes("/join/") ||
        location.pathname.includes("/main") ||
        location.pathname.includes("/template/"))
    ) {
      isTokenUrl = true;
    }

    if (auth.email && auth.isAdmin) {
      history.push("/admin");
    } else if (auth.email && !auth.isAdmin && !isTokenUrl) {
      history.push("/home");
    }
  }, [auth, history]);

  navigator.sayswho = (function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M;
  })();

  if (
    (navigator.sayswho[0] !== "Chrome" && navigator.sayswho[0] !== "Safari") ||
    (navigator.sayswho[0] === "Chrome" && Number(navigator.sayswho[1]) < 12) ||
    (navigator.sayswho[0] === "Safari" && Number(navigator.sayswho[1]) < 5.2)
  ) {
    return <NotSupport />;
  }

  // const MyFallbackComponent = ({error, resetErrorBoundary}) => {
  //   return (
  //     <div role="alert">
  //       <p>Something went wrong:</p>
  //       <pre>{error.message}</pre>
  //       <button onClick={resetErrorBoundary}>Try again</button>
  //     </div>
  //   )
  // }

  return (
    <>
      <Switch>
        <Route path="/join/:type" component={GetMapByToken} />
        <Route path="/template/:type" component={TemplateCloneByUrl} />
        <Route path="/login" exact component={Login} />
        <AuthenticatedRoute path="/admin" component={AdminDashboard} />
        <AuthenticatedRoute path="/main" component={Main} />
        <AuthenticatedRoute path="/home" component={Home} />
        <Route path="/finishSignUp/:uid/:email" component={FinishSignUp} />
        <Route path="/change-password/:type" component={ChangePassword} />
        <Redirect to="/login" />
      </Switch>
    </>
  );
};

export default withRouter(App);
