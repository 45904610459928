import React from "react";
import * as moment from "moment";
import Avatar from "@material-ui/core/Avatar";

import {
  getAvatarChar,
  getAvatarColor,
  USE_FIRST_NAME,
} from "../../utils/global";

import "../../styles/components/ChatBar/MyMessageBubble.scss";

const MyMessageBubble = ({ message, showTime }) => {
  const useFirstname =
    localStorage.getItem(USE_FIRST_NAME) === "true" ? true : false;

  return (
    <>
      {showTime && (
        <span className="timestamp">
          {moment(message.date.seconds * 1000).calendar()}
        </span>
      )}
      <div className="bubble-container">
        <div className="bubble">
          <span>
            {useFirstname
              ? message.username[0]
                ? message.username[0].split(" ")[0]
                : ""
              : message.username[0]}
            :
          </span>
          {message.text}
        </div>
        <Avatar
          className={
            message.color !== ""
              ? ""
              : `avt-${getAvatarColor(message.username[0])}`
          }
          style={{
            backgroundColor: message.color,
            color: "white",
            fontFamily: "Nunito !important",
          }}
        >
          {getAvatarChar(message.username[0])}
        </Avatar>
      </div>
    </>
  );
};

export default MyMessageBubble;
