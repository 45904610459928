import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DragM from "dragm";
import {
  editNote,
  removeNote,
  setnoteFocus,
} from "../../redux/actions/noteAction";
import "../../styles/components/Canvas/NotesContainer.scss";

const EditNote = ({ data, key }) => {
  const { notefocus } = useSelector((state) => state.note);
  const [openInpute, setOpenInput] = useState(false);
  const [value, setInpute] = useState(data.text);
  const dispatch = useDispatch();

  const handleNote = (data) => {
    setInpute(data);
  };
  const handleClick = () => {
    setOpenInput(!openInpute);
  };
  const handleEdit = (e, id) => {
    if (e.key === "Enter") {
      dispatch(editNote(id, value));
      handleClick();
      const newNotefocus = notefocus;
      const fIndex = newNotefocus?.findIndex((obj) => obj.saveId === id);
      newNotefocus[fIndex].text = value;
      dispatch(setnoteFocus(newNotefocus));
    }
  };
  const handleDelete = (id) => {
    dispatch(removeNote(id));
    let allNotefocus = notefocus.filter(
      (fNote) => JSON.stringify(fNote.saveId) !== JSON.stringify(id)
    );
    dispatch(setnoteFocus(allNotefocus));
  };
  return (
    <DragM>
      <div className="note" style={{ display: "block" }}>
        <div className="note-header">
          <img
            alt="noteEdit"
            src={"/assets/note-edit.svg"}
            onClick={() => handleClick()}
          />
          <img
            alt="noteDelete"
            src={"/assets/note-delete.svg"}
            onClick={() => handleDelete(data.saveId)}
          />
        </div>
        <div className="note-container">
          {!openInpute ? (
            <span>{data.text}</span>
          ) : (
            <input
              placeholder="Enter your note"
              value={value}
              onChange={(e) => {
                handleNote(e.target.value);
              }}
              onKeyPress={(e) => handleEdit(e, data.saveId)}
              ref={(input) => input && input.focus()}
            />
          )}
        </div>
      </div>
    </DragM>
  );
};

export default EditNote;
