import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
// import { css } from "@emotion/react";
// import { BounceLoader } from "react-spinners";
import { getGPUTier } from "detect-gpu";
import { useHistory } from "react-router-dom";
import canvas from "../../core/canvas.bundle";
import ZoomToolbar from "./ZoomToolbar";
import PointEditDialog from "./PointEditDialog";
import NotesContainer from "./NotesContainer";
import ConfirmDialog from "./ConfirmDialog";
import { ErrorModal } from "../modal/index";
import html2Canvas from "html2canvas";
import {
  uploadMapData,
  uploadMapImage,
  User_CurrentlyInMap,
  saveCurrentMapID,
  clearCurrentMapData,
  startedNewMap,
  saveSelectedLoopArea,
  setLoopView,
  deleteMap,
  setActivemap,
  showHoveringLoop,
  updateCycleStatus,
} from "../../redux/actions/nodeAction";
import { saveSelectedTabIndex } from "../../redux/actions/folderAction";
import {
  deleteMouseMovement,
  uploadMovement,
} from "../../redux/actions/movementAction";
import {
  USE_FIRST_NAME,
  getAvatarColor,
  isDifferentTwoObj,
} from "../../utils/global";

import "react-toastify/dist/ReactToastify.css";
import "../../styles/components/Canvas/index.scss";

const source = `
<html lang="en">
<head>
  <title>Cauzality</title>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Cauzality Canvas</title>
  <link
    href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap"
    rel="stylesheet"
  />
  <style>
    /* Global reset */
    html,
    body {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
      background: transparent;
    }

    canvas {
      margin: 0;
      padding: 0;
      overflow: hidden;
      position: absolute;
      image-rendering: optimizeSpeed;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimize-contrast;
      image-rendering: crisp-edges;
      image-rendering: pixelated;
      -ms-interpolation-mode: nearest-neighbor;
    }
  </style>
</head>
<body>
  <canvas id="scene"></canvas>
  <script type="text/javascript">${canvas}</script>
</body>
</html>`;
const MESSAGE_PREFIX = "CANVAS";

const U_TIME = 5;
let u_tick = 0;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const CanvasComponent = ({
  onShowFeedbackBar,
  setBackDrop,
  status,
  playstatus,
  showVariableText,
  lowResMode,
  openNote,
  CloseNote,
  setFocuedCycle,
  isModifiedTemplate,
  setStatusOfVisibility,
  focusedCycle,
}) => {
  //showAnimations,
  const dispatch = useDispatch();
  const {
    selectedMapData: selectedMap,
    loopArea,
    loopView,
    isLoopLabels,
    isHideFully,
    unUsepoint,
    colorSystem,
    cycles,
  } = useSelector((state) => state.node);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [isOpenNote, setOpenNote] = useState(true);
  const prevSelectedMap = usePrevious(!isOpenNote ? selectedMap : null);
  const usersMovements = useSelector((state) => state.movement);
  const user = useSelector((state) => state.auth);

  const versionDate = "";
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mapData, setMapData] = useState(selectedMap);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [debouncedMapData] = useDebounce(mapData, 10000);
  const canvasView = useRef(null);
  const [canvasPayload, setCanvasPayload] = useState(null);
  // const [statusOfVisibility, setStatusOfVisibility] = useState(null);
  const [updatedTime, setUpdatedTime] = useState(null);
  const [oldUserMovements, setOldUserMovements] = useState(null);
  const [isOpenConfirmDlg, setOpenConfirmDlg] = useState("");
  const [customCycles, setCustomCycles] = useState([]);
  // const [isHardware_acceleration, setHardware_acceleration] = useState(false);
  // const [isWebGl2Support, setWebGl2Support] = useState(false);
  const [dropValue, setDrovalue] = useState(null);
  const [data, setdata] = useState("");
  const auth = useSelector((state) => state.auth);
  let selectedMap_creator =
    selectedMap && selectedMap?.creator && selectedMap?.creator.length;
  let selectedMap_data =
    selectedMap && selectedMap?.data && selectedMap?.data.length;

  useEffect(() => {
    if (selectedMap && selectedMap?.creator) {
      selectedMap.creator.forEach((value, index) => {
        (auth.email === value.email ||
          sessionStorage.getItem("random_id") === value.random_id) &&
          // Getting editor value, if first in the index you are owner, otherwise you fallback to editor
          setDrovalue(value.dropvalue || (index === 0 ? "Owner" : "Editor"));
      });
      // selectedMap.creator.map((value, index) => {
      //   if (
      //     auth.email === value.email ||
      //     sessionStorage.getItem("random_id") === value.random_id
      //   ) {
      //     // Getting editor value, if first in the index you are owner, otherwise you fallback to editor
      //     return setDrovalue(
      //       value.dropvalue || (index === 0 ? "Owner" : "Editor")
      //     );
      //   }
      // });
    }
    sendMessage({
      event: "roleCheck",
      res: {
        dropValue: dropValue,
        Template_type: selectedMap && selectedMap?.type,
        Permission: auth.permission,
        auth_name: auth.displayName,
        guest_name: sessionStorage.getItem("guestLogin"),
        ShowVariableText: showVariableText,
        LowResMode: lowResMode,
        isHideFully: isHideFully,
      },
    }); // ShowAnimations:showAnimations,

    if (data !== playstatus.onplay) {
      setdata(playstatus.onplay);
      sendMessage({
        event: "playloop",
        res: {
          loopView:
            playstatus && playstatus.value.data
              ? playstatus.value.data
              : playstatus.value,
          playstatus: playstatus.playloop,
        },
      });
    }
  }, [selectedMap, auth, dropValue, data]);
  const onBackButtonEvent = async (e) => {
    e.preventDefault();
    if (auth) {
      setBackDrop();
    }
    if (
      selectedMap_creator === 1 &&
      (selectedMap_data === 0 || selectedMap_data === undefined) &&
      selectedMap?.name === "My Map"
    ) {
      dispatch(saveSelectedTabIndex(0));
      dispatch(deleteMap(selectedMap));
      history.push("/home");
    } else {
      dispatch(saveSelectedTabIndex(0));
      await setTimeout(async () => {
        const canvas = await html2Canvas(
          document
            .querySelector("iframe")
            ?.contentDocument.body.querySelector("canvas")
        );
        const canvasImage = canvas.toDataURL();
        await dispatch(uploadMapImage(canvasImage));
        dispatch(User_CurrentlyInMap(auth?.email, false));
        dispatch(saveCurrentMapID(null));
        dispatch(clearCurrentMapData());
        dispatch(setActivemap(false));
      }, 500);
    }
  };

  const gotoCenter = () => {
    if (!selectedMap) {
      const centerPos = { x: 0, y: 0 };
      const area = { x: 0, y: 0 };
      dispatch(saveSelectedLoopArea(centerPos, area, true));
      return;
    }

    const { data } = selectedMap;

    const pointPositions = data.map((point) => ({
      x: point.x,
      y: point.y,
    }));

    if (!pointPositions || pointPositions.length === 0) {
      return;
    }

    let minX = pointPositions[0].x,
      minY = pointPositions[0].y,
      maxX = pointPositions[0].x,
      maxY = pointPositions[0].y;
    pointPositions.forEach((point) => {
      minX = minX > point.x ? point.x : minX;
      minY = minY > point.y ? point.y : minY;
      maxX = maxX < point.x ? point.x : maxX;
      maxY = maxY < point.y ? point.y : maxY;
    });

    const centerPosition = { x: (minX + maxX) / 2, y: (minY + maxY) / 2 };
    let areaSize = {
      x: maxX - minX > 6000 ? 6000 : maxX - minX,
      y: maxY - minY > 3600 ? 3600 : maxY - minY,
    };
    dispatch(saveSelectedLoopArea(centerPosition, areaSize, true));
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    const initalSelectLooplabel = selectedMap?.cycles?.map((cur) => {
      return { ...cur, isOpen: false, isEditable: false };
    });
    dispatch(updateCycleStatus(initalSelectLooplabel));
  }, [dispatch, isLoading]);

  const BackDrop = async (e) => {
    e.preventDefault();
    dispatch(User_CurrentlyInMap(auth?.email, false));
  };
  useEffect(() => {
    window.addEventListener("beforeunload", BackDrop);
    return () => {
      window.removeEventListener("beforeunload", BackDrop);
    };
  }, []);
  // useEffect(() => {
  //   const detectAcceleration = async () => {
  //     const gpuTier = await getGPUTier();
  //     if (navigator.appVersion.indexOf("Win") != -1 || navigator.appVersion.indexOf("Linux") != -1)
  //     if (gpuTier.type === "BLOCKLISTED" || gpuTier.type === "FALLBACK") {
  //       setTimeout(() => {
  //         setLoading(true);
  //         setHardware_acceleration(true);
  //       }, 4000);
  //     }
  //   };

  //   detectAcceleration();
  // }, []);
  useEffect(() => {
    async function isSuppotedWebGL2() {
      try {
        // const canvas = document
        //   .querySelector("iframe")
        //   .contentDocument.body.querySelector("#scene");
        // const gl = canvas.getContext('webgl2', { powerPreference: "high-performance" }) || canvas.getContext('experimental-webgl', { powerPreference: "high-performance" });
        // canvas.addEventListener("webglcontextlost", (event) => {
        //   setErrorModel(true);
        // });
        // if (!gl) {
        //   setLoading(true);
        //   setWebGl2Support(true);
        // }
        const gpuTier = await getGPUTier();
        if (
          navigator.appVersion.indexOf("Win") !== -1 ||
          navigator.appVersion.indexOf("Linux") !== -1
        ) {
          if (gpuTier.type === "BLOCKLISTED" || gpuTier.type === "FALLBACK") {
            setTimeout(() => {
              setLoading(true);
              setErrorModel(true);
              // setHardware_acceleration(true);
            }, 4000);
          }
        }
      } catch (e) {
        // console.log("error_34", e);
      }
    }
    isSuppotedWebGL2();
  }, [isLoading, getGPUTier]);

  useEffect(() => {
    sendMessage({ event: "changeColorSystem", res: colorSystem });
  }, [colorSystem]);

  useEffect(() => {
    if (selectedMap && usersMovements && usersMovements?.users && !isOpenNote) {
      const useFirstname =
        localStorage.getItem(USE_FIRST_NAME) === "true" ? true : false;
      // const displayName = useFirstname
      //   ? user.displayName.split(" ")[0]
      //   : user.displayName;
      var checkName = sessionStorage.getItem("guestLogin")
        ? `${sessionStorage.getItem("guestLogin")} (Guest)`
        : user?.displayName;
      const selectedUsersActions = usersMovements?.users.filter((movement) => {
        return (
          movement.mapId === selectedMap?.id && checkName !== movement.userName
        );
      });

      if (selectedUsersActions && selectedUsersActions?.length > 0) {
        sendMessage({
          event: "usersMovement",
          res: selectedUsersActions?.map((action) => ({
            ...action,
            color: action?.color
              ? action?.color?.substr(1)
              : getAvatarColor(action?.userName),
            useFirstname,
          })),
        });
      }
    }
  }, [selectedMap, user, usersMovements, isOpenNote]);

  useEffect(() => {
    if (debouncedMapData) {
      const canvasImage = document
        .querySelector("iframe")
        .contentDocument.body?.children[0]?.toDataURL("image/jpeg", 1.0);

      if (canvasImage) {
        dispatch(uploadMapImage(canvasImage));
      }
    }
  }, [debouncedMapData, dispatch]);

  useEffect(() => {
    sendMessage({
      event: "addingFogHandler",
      res: {
        cycles: selectedMap?.cycles,
        isLoopLabels,
        zoomLevel,
        focusedCycle,
      },
    });
  }, [selectedMap?.cycles, isLoopLabels, zoomLevel, focusedCycle]);

  useEffect(() => {
    const changedMapData = getChangedMapData(selectedMap, prevSelectedMap);
    if (selectedMap && selectedMap?.data && !isOpenNote) {
      if (
        !prevSelectedMap ||
        !prevSelectedMap.data ||
        prevSelectedMap.data === []
      ) {
        sendMessage({ event: "setNodes", res: selectedMap?.data });
        // sendMessage({
        //   event: "addingFogHandler",
        //   res: {
        //     cycles: selectedMap.cycles,
        //     isLoopLabels,
        //     zoomLevel,
        //     focusedCycle,
        //   },
        // });
        gotoCenter();
      } else if (changedMapData.data?.length > 0) {
        sendMessage({ event: "updateNodes", res: changedMapData.data });
        sendMessage({
          event: "addingFogHandler",
          res: {
            cycles: selectedMap.cycles,
            isLoopLabels,
            zoomLevel,
            focusedCycle,
          },
        });
      } else if (changedMapData.ways?.length > 0) {
        sendMessage({ event: "updateArrows", res: changedMapData.ways });
      }

      if (
        !prevSelectedMap ||
        !prevSelectedMap.ways ||
        prevSelectedMap.ways === []
      ) {
        sendMessage({ event: "setArrows", res: selectedMap?.ways });
      }
    }

    if (!selectedMap) {
      sendMessage({ event: "setNodes", res: [] });
      sendMessage({ event: "setArrows", res: [] });
      gotoCenter();
    }
    sendMessage({ event: "changeColorSystem", res: colorSystem });
  }, [
    selectedMap,
    isOpenNote,
    // , prevSelectedMap
  ]);

  useEffect(() => {
    if (!isOpenNote) {
      sendMessage({ event: "changeVisibility", res: status }); //statusOfVisibility
    }
  }, [status, isOpenNote]);

  useEffect(() => {
    onReceiveMessage(canvasPayload);
    if (selectedMap?.data?.length === 0) {
      sendMessage({ event: "zoomChange", res: { level: 100, type: "button" } });
      sendMessage({ event: "onCenter" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasPayload]);

  useEffect(() => {
    if (usersMovements && usersMovements.users) {
      // const useFirstname =
      //   localStorage.getItem(USE_FIRST_NAME) === "true" ? true : false;
      // const displayName = useFirstname
      //   ? user.displayName.split(" ")[0]
      //   : user.displayName;
      var checkName = sessionStorage.getItem("guestLogin")
        ? `${sessionStorage.getItem("guestLogin")} (Guest)`
        : user.displayName;
      const selectedUsersActions = usersMovements.users.filter((movement) => {
        return (
          selectedMap &&
          movement.mapId === selectedMap?.id &&
          checkName !== movement.userName
        );
      });
      if (oldUserMovements && oldUserMovements?.length > 0) {
        if (selectedUsersActions && selectedUsersActions?.length > 0) {
          selectedUsersActions.forEach((action) => {
            const oldUser = oldUserMovements.find(
              (oldMove) => oldMove.id === action.id
            );
            if (oldUser && oldUser.x === action.x && oldUser.y === action.y) {
              dispatch(deleteMouseMovement(action.id));
            }
          });
        }
      }
      setOldUserMovements(selectedUsersActions);
    }
  }, [
    updatedTime,
    dispatch,
    // , canvasView
  ]);

  useEffect(() => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(
      messageEvent,
      (e) => {
        if (e.data && typeof e.data === "string") {
          const data = JSON.parse(e.data);
          if (data.prefix === "CANVAS" && data?.payload) {
            setCanvasPayload(data?.payload);
          }
        }
      },
      false
    );

    setLoading(true);
    setTimeout(() => {
      setOpenNote(false);
      dispatch(startedNewMap(false));
    }, 3000);
    setTimeout(() => {
      setLoading(false);
    }, 4000);

    const timer = setInterval(() => {
      setUpdatedTime(new Date());
    }, 10000);

    return () => {
      dispatch(deleteMouseMovement());
      clearTimeout(timer);
    };
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("beforeunload")
  //   return () => {
  //     window.removeEventListener("beforeunload", updateTime);
  //   };
  // });

  const updateTime = () => {
    setUpdatedTime(new Date());
  };

  // useEffect(() => {
  //   console.log("blur")
  //   return () => {
  //     window.removeEventListener("blur", updateTime);
  //   };
  // });

  useEffect(() => {
    window.addEventListener("beforeunload", updateTime);
    window.addEventListener("visibilitychange", updateTime);
    window.addEventListener("blur", updateTime);
    return () => {
      window.removeEventListener("beforeunload", updateTime);
      window.removeEventListener("visibilitychange", updateTime);
      window.removeEventListener("blur", updateTime);
    };
  }, [updatedTime]);

  useEffect(() => {
    if (loopArea && !isOpenNote) {
      sendMessage({ event: "changeViewArea", res: loopArea });
    }
  }, [loopArea, isOpenNote]);

  useEffect(() => {
    if (!isOpenNote) {
      sendMessage({
        event: "changeLoopVisibility",
        res: loopView,
      });
    }
  }, [loopView, isOpenNote]);

  useEffect(() => {
    if (loopView && cycles) {
      if (cycles.length === 1) {
        // const stringifiedloop = JSON.stringify(loopView);
        const existedIndex = cycles?.findIndex((cycle) => {
          // const stringifiedCycle = JSON.stringify(cycle);
          return JSON.stringify(cycle) === JSON.stringify(loopView);
        });
        if (existedIndex < 0) {
          dispatch(setLoopView(null, isHideFully, unUsepoint));
        }
      } else {
        const existedIndex = cycles?.findIndex((cycle, idx) => {
          let isExisted = false;
          cycle.forEach((e) => {
            if (loopView.includes(e)) {
              isExisted = true;
            }
          });
          if (isExisted) return idx;
          return -1;
        });
        if (existedIndex < 0) {
          dispatch(setLoopView(null, isHideFully, unUsepoint));
        }
      }
    }
  }, [loopView, cycles, dispatch]);

  const getChangedMapData = (updatedMapData, originMapData) => {
    /**
     * GET UPDATED POINTS
     */
    const changedPointData = [];
    if (updatedMapData && updatedMapData.data) {
      updatedMapData.data.forEach((updatedPoint) => {
        if (!originMapData) {
          changedPointData.push({
            updatedObj: updatedPoint,
            method: "new point",
          });
          return;
        }

        if (originMapData.data) {
          const point = originMapData.data.find(
            (it) => it.nodeId === updatedPoint.nodeId
          );
          if (!point) {
            changedPointData.push({
              updatedObj: updatedPoint,
              method: "new point",
            });
          }
        }
      });
    }

    if (originMapData && originMapData.data) {
      originMapData.data.forEach((point, index) => {
        if (!updatedMapData || !updatedMapData.data) {
          changedPointData.push({ updatedObj: point, method: "delete point" });
          return;
        }

        const updatedPoint = updatedMapData.data.find(
          (it) => it.nodeId === point.nodeId
        );
        if (!updatedPoint) {
          changedPointData.push({ updatedObj: point, method: "delete point" });
        } else if (!isDifferentTwoObj(point, updatedPoint)) {
          changedPointData.push({
            updatedObj: updatedPoint,
            method: "update point",
          });
        }
      });
    }

    /**
     * GET UPDATED WAYS
     */
    const changedWayData = [];
    if (updatedMapData && updatedMapData.ways) {
      updatedMapData.ways.forEach((updatedWay) => {
        if (!originMapData) {
          changedWayData.push({
            updatedObj: updatedWay,
            method: "new way",
          });
          return;
        }

        if (originMapData.ways) {
          const way = originMapData.ways.find(
            (it) => it.nodeId === updatedWay.nodeId
          );
          if (!way) {
            changedWayData.push({ updatedObj: updatedWay, method: "new way" });
          }
        }
      });
    }

    if (originMapData && originMapData.ways) {
      originMapData.ways.forEach((way, index) => {
        if (!updatedMapData || !updatedMapData.ways) {
          changedWayData.push({ updatedObj: way, method: "delete way" });
          return;
        }
        const updatedWay = updatedMapData.ways.find(
          (it) => it.nodeId === way.nodeId
        );
        if (!updatedWay) {
          changedWayData.push({ updatedObj: way, method: "delete way" });
        } else if (!isDifferentTwoObj(way, updatedWay)) {
          changedWayData.push({
            updatedObj: updatedWay,
            method: "update way",
          });
        }
      });
    }

    return { data: changedPointData, ways: changedWayData };
  };

  const toggleModal = (payload) => {
    if (payload) {
      const selectedPoint = payload?.mapData?.data?.find(
        (point) => point.nodeId === payload.id
      );
      setSelectedPoint(selectedPoint);
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  };

  const onReceiveMessage = async (payload) => {
    if (payload && payload?.event) {
      if (payload?.event === "toggleLoopLabel") {
        const updatedLoopLabel = selectedMap.cycles.map((obj, index) => {
          if (index == payload?.payload.index) {
            obj.isOpen = payload?.payload?.isOpen;
            obj.isEditable = payload?.payload?.isEditable;
            return {
              ...obj,
              isOpen: payload?.payload?.isOpen,
              isEditable: payload?.payload?.isEditable,
            };
          }
          return obj;
        });
        dispatch(updateCycleStatus(updatedLoopLabel));
      }
      if (payload?.event === "updateCycleLoopLabel") {
        const updatedLoopLabel = selectedMap.cycles.map((obj, index) => {
          if (index == payload?.payload.index) {
            obj.x = payload?.payload.x;
            obj.y = payload?.payload.y;
          }
          return obj;
        });
        dispatch(updateCycleStatus(updatedLoopLabel));
      }
      if (payload?.event === "updateCycle") {
        const updated_obj = selectedMap.cycles.map((obj, index) => {
          if (
            index == payload?.payload.index &&
            payload?.payload.cycle_name !== ""
          ) {
            obj.name =
              payload?.payload?.cycle_name?.charAt(0).toUpperCase() +
              payload?.payload?.cycle_name.slice(1);
            obj.isOpen = payload?.payload?.isOpen;
            obj.isEditable = payload?.payload?.isEditable;
          }
          return obj;
        });
        dispatch(updateCycleStatus(updated_obj));
      }
      if (payload?.event === "spotlightCycle") {
        setStatusOfVisibility(null);
        if (focusedCycle === null) {
          setFocuedCycle([payload?.payload.element]);
          dispatch(
            setLoopView(payload?.payload.element.data, isHideFully, unUsepoint)
          );
        } else {
          const fIndex = focusedCycle?.findIndex(
            (fcycle) =>
              JSON.stringify(fcycle.data) ===
              JSON.stringify(payload?.payload.element.data)
          );

          let newFocusedCycles = [];
          if (fIndex > -1) {
            newFocusedCycles = focusedCycle.filter(
              (fcycle) =>
                JSON.stringify(fcycle.data) !==
                JSON.stringify(payload?.payload.element.data)
            );
            if (newFocusedCycles.length === 0) {
              setFocuedCycle(null);
            } else {
              setFocuedCycle(newFocusedCycles);
            }
          } else {
            newFocusedCycles = [...focusedCycle, payload?.payload.element];
            setFocuedCycle(newFocusedCycles);
          }

          let newLoopView = [];
          newFocusedCycles.forEach((fcycle) => {
            newLoopView = [...newLoopView, ...fcycle.data];
          });
          dispatch(setLoopView(newLoopView, isHideFully, unUsepoint));
        }

        const newCustomCycles = customCycles.map((item) => {
          if (
            JSON.stringify(item.data) ===
            JSON.stringify(payload?.payload.element.data)
          ) {
            return {
              ...item,
              view: !payload?.payload.element.view,
            };
          }
          return item;
        });
        setCustomCycles(newCustomCycles);
      }
      if (payload?.event === "updateMap") {
        dispatch(
          uploadMapData(getChangedMapData(payload?.payload, selectedMap))
        );
        setMapData(payload?.payload);
        var data = [],
          Cyclenode = [],
          unUsepoint = [];
        payload.payload.data.map((item, index) => data.push(item.nodeId));
        cycles &&
          cycles.map((item) => item.map((value) => Cyclenode.push(value)));
        data.map(
          (item, index) => !Cyclenode.includes(item) && unUsepoint.push(item)
        );
        dispatch(setLoopView(loopView, isHideFully, unUsepoint));
        // sendMessage({
        //   event: "changeLoopVisibility",
        //   res: {
        //     loopView: loopView,
        //     isHideFully: isHideFully,
        //     unUsepoint: unUsepoint,
        //   },
        // });
        const canvas = await html2Canvas(
          document
            .querySelector("iframe")
            .contentDocument.body.querySelector("canvas")
        );
        const canvasImage = canvas.toDataURL();
        dispatch(uploadMapImage(canvasImage));
      } else if (payload?.event === "pointSelected" && payload?.payload.id) {
        toggleModal(payload?.payload);
      } else if (payload?.event === "mousePosition") {
        uploadMyAction(payload?.payload);
      } else if (payload?.event === "showReinAnimation") {
        dispatch(showHoveringLoop(payload?.payload));
      } else if (payload?.event === "zoomLevelChange") {
        if (payload?.payload === "up") {
          if (zoomLevel < 100) {
            onChangeZoomLevel(zoomLevel + 5, "wheel");
          }
        } else if (payload?.payload === "down") {
          if (zoomLevel > 10) {
            onChangeZoomLevel(zoomLevel - 5, "wheel");
          }
        }
      } else if (payload?.event === "deleteObject") {
        if (
          dropValue === "Owner" ||
          dropValue === "Editor" ||
          sessionStorage.getItem("guestRole") === "Editor"
        ) {
          setOpenConfirmDlg(payload?.payload);
        }
      } else if (payload?.event === "mapCreated") {
        setLoading(false);
      }
    }
  };

  const uploadMyAction = (payload) => {
    if (selectedMap) {
      u_tick += 1;
      if (u_tick < U_TIME) {
        return;
      }

      u_tick = 0;
      // const useFirstname =
      //   localStorage.getItem(USE_FIRST_NAME) === "true" ? true : false;
      var colors = selectedMap?.creator?.filter(
        (item) =>
          sessionStorage.getItem("guestLogin") === item?.displayName ||
          auth?.email === item?.email //localStorage.getItem("email")
      );
      // const displayName = useFirstname
      //   ? user.displayName.split(" ")[0]
      //   : user.displayName;
      const userActionData = {
        mapId: selectedMap?.id ? selectedMap?.id : "",
        color:
          colors !== null && colors[0] && colors[0].color
            ? colors[0].color
            : "",
        userName: sessionStorage.getItem("guestLogin")
          ? `${sessionStorage
              .getItem("guestLogin")
              .replace(/[&\\#-,+()$~%.'":*?<>{}]/g, "")} (Guest)`
          : user?.displayName?.replace(/[&\\#-,+()$~%.'":*?<>{}]/g, ""),
        x: payload.x,
        y: payload.y,
      };
      if (auth?.email || sessionStorage.getItem("guestLogin")) {
        dispatch(uploadMovement(userActionData));
      }
    }
  };

  const onChangeZoomLevel = (level, type) => {
    setZoomLevel(level);
    sendMessage({ event: "zoomChange", res: { level: level, type: type } });
    if (selectedMap?.data?.length === 0) {
      sendMessage({ event: "zoomChange", res: { level: 100, type: type } });
    }
  };

  const sendMessage = (payload) => {
    const message = JSON.stringify({
      prefix: MESSAGE_PREFIX,
      payload,
    });
    canvasView.current.contentWindow.postMessage(message, "*");
  };

  const updatePoint = (pointData) => {
    let newInterestPoints = mapData.data.filter(
      (point) => point.nodeId !== pointData.nodeId
    );
    newInterestPoints.push(pointData);
    dispatch(
      uploadMapData(
        getChangedMapData(
          {
            data: newInterestPoints,
            ways: mapData.ways,
          },
          mapData
        )
      )
    );
    setMapData({
      data: newInterestPoints,
      ways: mapData.ways,
    });
  };

  const onDeletePoint = (pointData) => {
    let newInterestPoints = mapData?.data?.filter(
      (point) => point.nodeId !== pointData.nodeId
    );
    let newCauseways = mapData?.ways?.filter(
      (way) => !way.points.includes(pointData.nodeId)
    );
    var data = [],
      Cyclenode = [],
      unUsepoint = [];
    mapData.data.map((item, index) => data.push(item.nodeId));
    cycles && cycles.map((item) => item.map((value) => Cyclenode.push(value)));
    data.map(
      (item, index) => !Cyclenode.includes(item) && unUsepoint.push(item)
    );
    if (unUsepoint.includes(pointData.nodeId)) {
      dispatch(setLoopView(loopView, isHideFully, unUsepoint));
      // sendMessage({
      //   event: "changeLoopVisibility",
      //   res: {
      //     loopView: loopView,
      //     isHideFully: isHideFully,
      //     unUsepoint: unUsepoint,
      //   },
      // });
    } else {
      setFocuedCycle(null);
      dispatch(setLoopView(null, isHideFully, unUsepoint));
      // sendMessage({
      //   event: "changeLoopVisibility",
      //   res: {
      //     loopView: null,
      //     isHideFully: isHideFully,
      //     unUsepoint: unUsepoint,
      //   },
      // });
      sendMessage({ event: "changeVisibility", res: status });
    }
    dispatch(
      uploadMapData(
        getChangedMapData(
          {
            data: newInterestPoints,
            ways: newCauseways,
          },
          mapData
        )
      )
    );
    setMapData({
      data: newInterestPoints,
      ways: newCauseways,
    });
    setIsOpenModal(false);
    sendMessage({ event: "setNodes", res: newInterestPoints });
    sendMessage({ event: "setArrows", res: newCauseways });
  };

  const handleCloseConfirmModal = () => {
    sendMessage({ event: "cancelDeleteObj", res: [] });
    setOpenConfirmDlg("");
  };
  const handleDeleteObject = () => {
    sendMessage({ event: "deleteObj", res: [] });
    setOpenConfirmDlg("");
  };
  const closeErrorMessageHandal = () => {
    setErrorModel(!errorModel);
  };
  return (
    <div className="canvas__container">
      <iframe ref={canvasView} srcDoc={source} title="Pixi" />
      <ZoomToolbar
        gotoCenter={gotoCenter}
        zoomLevel={zoomLevel}
        setZoomLevel={onChangeZoomLevel}
        showVariableText={showVariableText}
        // status={statusOfVisibility}
        // onChangeVisibility={setStatusOfVisibility}
        onShowFeedbackBar={onShowFeedbackBar}
      />
      <NotesContainer open={openNote} onclose={CloseNote} />
      <span className="txt-version">{versionDate}</span>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {dropValue === "Owner" ||
      dropValue === "Editor" ||
      sessionStorage.getItem("guestRole") === "Editor" ? (
        <PointEditDialog
          open={isOpenModal}
          pointData={selectedPoint}
          onCloseModal={toggleModal}
          updatePoint={updatePoint}
          onDelete={onDeletePoint}
        />
      ) : (
        ""
      )}
      <ConfirmDialog
        open={Boolean(isOpenConfirmDlg)}
        title={`Delete ${isOpenConfirmDlg}`}
        description={`Do you want to delete this ${
          isOpenConfirmDlg !== "connector" ? "variable" : "connector"
        }?`}
        okButtonLabel="Ok"
        cancelButtonLabel="Cancel"
        onClosed={handleCloseConfirmModal}
        onConfirmed={handleDeleteObject}
      />
      {/* <NoteDialog
        open={isOpenNote}
        onClosed={() => {
          setOpenNote(false);
          setLoading(true);
          dispatch(startedNewMap(false));
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }}
      /> */}
      {isLoading && (
        <div className="loading-container">
          <div className="backdrop">
            <img className="center" src="/assets/ico-menu.svg" alt="" />
            <p className="center">{`Loading ${
              isModifiedTemplate ? "template" : "map"
            }...`}</p>
          </div>
          {/* <BounceLoader
            css={css`
              display: block;
            `}
            size={80}
            color={"#7ec657"}
            loading={isLoading}
          /> */}
        </div>
      )}
      {/* {isWebGl2Support && (
        <div className="offline-error">
          Your computer does not support WebGL2.
        </div>
      )} */}
      {/* {isHardware_acceleration && (
        <div className="offline-error">
          Please enable "Use hardware acceleration when available" in your
          browser settings.
        </div>
      )} */}
      <ErrorModal open={errorModel} hide={() => closeErrorMessageHandal()} />
    </div>
  );
};

export default CanvasComponent;
